<template>
    <Section>
      <Container>
        <div class="title-bar">
          <div class="title-bar-title">
            <h5>Résumé du protocole</h5>
            <h2>{{ essai?.protocole?.titre }}</h2>
          </div>
          <div class="title-bar-action">
            <Btn class="margin-bottom"
              text="Imprimer le plan"
              color="default"
              icon="print"
              @click="printPlan('pdf')" />
            <Btn class="margin-bottom"
              text="Télécharger le plan (xls)"
              color="default"
              icon="print"
              @click="printPlan('xls')" />
          </div>
        </div>

        <div class="protocol-counters">
          <div class="protocol-counter">
            <div class="protocol-counter-value">{{ facteur.length }}</div>
            <div class="protocol-counter-label">Facteurs</div>
          </div>
          <div class="protocol-counter">
            <div class="protocol-counter-value">{{ modalite.length }}</div>
            <div class="protocol-counter-label">
              <template v-if="facteur.length === 1">
                Modalités
              </template>
              <template v-if="facteur.length > 1">
                Combinaisons de modalités
              </template>
            </div>
          </div>
        </div>

        <div class="grid grid--compact">
          <div class="tmp-col">
            <KeyValue label="Type de dispositif"
              :value="essai?.plan?.type?.designation" />
          </div>
          <div class="tmp-col">
            <KeyValue label="Facteur principal"
              :value="essai?.plan?.facteur?.type?.designation" />
          </div>
          <div class="tmp-col">
            <KeyValue label="Nombre de répétitions"
              :value="essai?.plan?.nb_repetitions" />
          </div>
          <div class="tmp-col">
            <KeyValue label="Orientation générale"
              :value="essai?.plan?.orientation_generale?.designation" />
          </div>
        </div>
      </Container>
    </Section>

    <Section>
      <Container>
        <div class="grid">
          <div class="tmp-col">
            <DisplayPlanOptions v-if="essai?.plan"
            :planId="essai?.plan?.id"
            @display-plan-options='updateDisplayOptions' />
          </div>
          <div class="tmp-col">
            <DisplayPlanModalitiesOptions v-if="essai?.plan"
            :planId="essai?.plan?.id"
            :essai="essai"
            :fromParent="true"
            @display-plan-modalities-options='updateDisplayModalitiesOptions' />
          </div>

        </div>
      </Container>
    </Section>

    <Section>
      <Container>
        <MicroParcels v-if="microParcelsData.length > 0"
          :microParcelsData="microParcelsData"
          :isEditable="false"
          :displayBorders="true"
          :borders="bordersVmodel"
          :displayHeaders="displayOptions?.options?.displayHeaders"
          :displayGeo="displayOptions?.options?.displayGeo"
          :displayComputedId="displayOptions?.options?.displayComputedId"
          :displayRepetition="displayOptions?.options?.displayRepetition"
          :displayModalityNumber="displayOptions?.options?.displayModalityNumber"
          :displayDesignation="displayOptions?.options?.displayDesignation"
          :displayColors="displayOptions?.options?.displayColors"
          :hiddenModalities="displayOptions?.modalites_off"
          :startingPoint="essai?.plan?.point_depart?.uid"
          canPanY />
        <template v-else> Le plan n'a pas encore été généré. </template>
      </Container>
    </Section>
  </template>

<script>
import Container from '@/components/layout/Container.vue'
import Section from '@/components/layout/Section.vue'
import MicroParcels from '@/components/micro-parcels/MicroParcels.vue'
import KeyValue from '@/components/form/KeyValue.vue'
import Btn from '@/components/base/Btn.vue'
import DisplayPlanOptions from '@/views/componentsViews/plan/DisplayPlanOptions.vue'
import DisplayPlanModalitiesOptions from '@/views/componentsViews/plan/DisplayPlanModalitiesOptions.vue'

export default {
  name: 'TestPlanSubview',
  components: {
    DisplayPlanOptions,
    Section,
    Container,
    MicroParcels,
    KeyValue,
    Btn,
    DisplayPlanModalitiesOptions,
  },

  props: {
    pageTitle: {
      type: String,
    },
    essai: {
      type: Object,
    },
  },
  data() {
    return {
      plan: null,
      modalite: [],
      facteur: [],
      microParcelsData: [],
      bordersVmodel: [],
      displayOptions: {},
    }
  },
  emits: ['updateEssais'],
  created() {
    if (this.essai?.id && this.essai?.plan?.id) {
      this.loadData()
    }
  },
  methods: {
    loadData() {
      this.emitter.emit('open-loader')

      this.fetchService
        .get(`protocole/${this.essai.protocole.id}/modalite`, {
          limit: 0,
        })
        .then((reponseModalite) => {
          this.modalite = reponseModalite.data
        })
      this.fetchService
        .get(`protocole/${this.essai.protocole.id}/facteur`, {
          limit: 0,
        })
        .then((reponseFacteur) => {
          this.facteur = reponseFacteur.data
        })

      this.fetchService
        .get(
          `essai/${this.essai.id}/plan/${this.essai.plan.id}/microparcelle`,
          {
            sort: 'x.ASC,y.ASC',
            limit: 0,
          },
        )
        .then((response) => {
          this.microParcelsData = response.data
          this.emitter.emit('close-loader')
        })

      this.bordersVmodel = this.essai.plan.bordures.map((bordure) => bordure.uid)
    },
    updateDisplayOptions(options) {
      this.displayOptions.options = options
    },
    updateDisplayModalitiesOptions(options) {
      if (options && options.length) {
        this.displayOptions.modalites_off = options.join(',')
      } else {
        this.displayOptions.modalites_off = ''
      }
    },
    printPlan(type) {
      const printOptions = {
        position_geographique: this.displayOptions?.options?.displayGeo ? 1 : 0,
        modalite_bloc: this.displayOptions?.options?.displayComputedId ? 1 : 0,
        numero_ligne: this.displayOptions?.options?.displayHeaders ? 1 : 0,
        numero_colonne: this.displayOptions?.options?.displayHeaders ? 1 : 0,
        numero_modalite: this.displayOptions?.options?.displayModalityNumber ? 1 : 0,
        numero_bloc: this.displayOptions?.options?.displayRepetition ? 1 : 0,
        libelle_modalite: this.displayOptions?.options?.displayDesignation ? 1 : 0,
        couleurs: this.displayOptions?.options?.displayColors ? 1 : 0,
      }

      if ('modalites_off' in this.displayOptions) {
        printOptions.modalites_off = this.displayOptions.modalites_off
      }

      this.fileService.exportFile(type, 'essai', this.essai.id, 'plan', printOptions)
    },
  },
}
</script>

  <style lang="scss" scoped>
  .title-bar {
    @include bp('xs') {
      display: flex;
      flex-wrap: wrap;
    }
  }

  .title-bar-title {
    flex-grow: 1;
  }

  .title-bar-action {
    button+button{
      margin-left: $gutter-quarter ;
    }
  }
  </style>
